import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Paper,
  Button,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Tabs,
  Tab
} from '@mui/material';

const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [taskData, setTaskData] = useState({
    name: '',
    type: '',
    type_new: 'partner', // default value for type_new
    details: '',
    reward: '',
    priority: 0, // default priority
    isRecurring: false,
    repeatInterval: '',
    active: true,
    maxCompletions: 0
  });
  const [validationError, setValidationError] = useState('');
  const [tab, setTab] = useState('join_tasks'); // Default to 'join_tasks'

  // Task types grouped into categories
  const taskGroups = {
    join_tasks: ['join_channel', 'join_chat','join_x'],
    recurring_tasks: ['isRecurring','richads', 'monetag', 'onclicka', 'tadsme', 'ads_gram'],
    other_tasks: ['join_other', 'visit_website', 'retweet','referral'],
    partner_tasks: 'partner',
    our_tasks: 'our'
  };

  const typeNewOptions = [
    { value: 'our', label: 'Our' },
    { value: 'partner', label: 'Partner' }
  ];

  const exampleDetails = {
    join_channel: JSON.stringify({ tglink: "", chat_id: "" }, null, 2),
    join_chat: JSON.stringify({ tglink: "", chat_id: "" }, null, 2),
    join_other: JSON.stringify({ url: "", api_url: "", expectedResponse: { "isValid": true } }, null, 2),
    visit_website: JSON.stringify({ url: "" }, null, 2)
  };

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/admin/tasks', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setTasks(response.data);
        setLoading(false);
      } catch (error) {
        setError('Error fetching tasks');
        setLoading(false);
      }
    };

    fetchTasks();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleClickOpen = (task) => {
    setCurrentTask(task);
    setTaskData(
      task
        ? {
            ...task,
            details: JSON.stringify(task.details, null, 2) // Ensure details are in JSON string format for the text field
          }
        : {
            name: '',
            type: '',
            type_new: 'partner', // default for adding new task
            details: '',
            reward: '',
            usdtReward: 0,
            priority: 0, // default priority
            isRecurring: false,
            repeatInterval: '',
            active: true,
            maxCompletions: 0
          }
    );
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTaskData({
      ...taskData,
      [name]: value
    });
  };

  const handleExampleDetails = () => {
    setTaskData({
      ...taskData,
      details: exampleDetails[taskData.type] || ''
    });
  };

  const validateTaskData = (data) => {
    if (!data.name || !data.type || !data.reward) {
      return 'Name, type, and reward are required fields.';
    }
    if (isNaN(data.reward) || data.reward < 0) {
      return 'Reward must be a positive number.';
    }
    if (isNaN(data.usdtReward) || data.reward < 0) {
      return 'Reward must be a positive number.';
    }
    try {
      JSON.parse(data.details);
    } catch (e) {
      return 'Details must be valid JSON.';
    }
    if (data.isRecurring && (isNaN(data.repeatInterval) || data.repeatInterval <= 0)) {
      return 'Repeat interval must be a positive number.';
    }
    if (isNaN(data.priority)) {
      return 'Priority must be a number.';
    }
    if (isNaN(data.maxCompletions) || data.maxCompletions < 0) {
      return 'Max completions must be a non-negative number.';
    }
    return '';
  };

  const handleSubmit = async () => {
    const error = validateTaskData(taskData);
    if (error) {
      setValidationError(error);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const dataToSend = {
        ...taskData,
        details: JSON.parse(taskData.details) // Ensure details are sent as a JSON object
      };

      if (currentTask) {
        await axios.put(`/admin/tasks/${currentTask._id}`, dataToSend, {
          headers: { Authorization: `Bearer ${token}` }
        });
      } else {
        await axios.post('/admin/tasks', dataToSend, {
          headers: { Authorization: `Bearer ${token}` }
        });
      }
      setOpen(false);
      const response = await axios.get('/admin/tasks', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTasks(response.data);
    } catch (error) {
      console.error('Error saving task:', error);
      setError('Error saving task');
    }
  };

  const handleDeleteDialogOpen = (task) => {
    setCurrentTask(task);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/admin/tasks/${currentTask._id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const response = await axios.get('/admin/tasks', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTasks(response.data);
      setDeleteDialogOpen(false);
    } catch (error) {
      setError('Error deleting task');
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Container>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  // Filter and sort tasks based on the current selected tab and active status
  const filteredTasks = tasks
    .filter((task) => {
      if (tab === 'join_tasks') return taskGroups.join_tasks.includes(task.type);
      if (tab === 'recurring_tasks') return task.isRecurring;
      if (tab === 'other_tasks') return taskGroups.other_tasks.includes(task.type);
      if (tab === 'partner_tasks') return task.type_new === 'partner';
      if (tab === 'our_tasks') return task.type_new === 'our';
      return true;
    })
    .sort((a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1));

  return (
    <Container>
      <Paper elevation={3} sx={{ padding: 3, margin: 3 }}>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            Tasks
          </Typography>
          <Button variant="contained" color="primary" onClick={() => handleClickOpen(null)}>
            Add Task
          </Button>

          {/* Horizontal Menu based on task types grouped */}
          <Tabs value={tab} onChange={handleTabChange} centered>
            <Tab label="Join Tasks" value="join_tasks" />
            <Tab label="Recurring Tasks" value="recurring_tasks" />
            <Tab label="Other Tasks" value="other_tasks" />
            <Tab label="Partner Tasks" value="partner_tasks" />
            <Tab label="Our Tasks" value="our_tasks" />
          </Tabs>

          <Box sx={{ mt: 2 }}>
            {filteredTasks.map((task) => (
              <Paper
                key={task._id}
                sx={{
                  padding: 2,
                  marginBottom: 2,
                  backgroundColor: task.active ? '#e8f5e9' : '#ffebee'
                }}
              >
                <Typography variant="h6">{task.name}</Typography>
                <Typography variant="body1">Type: {task.type}</Typography>
                <Typography variant="body1">Details: {JSON.stringify(task.details)}</Typography>
                <Typography variant="body1">Reward: {task.reward}</Typography>
                <Typography variant="body1">USDT Reward: {task.usdtReward}</Typography>
                <Typography variant="body1">Priority: {task.priority}</Typography>
                <Typography variant="body1">Category: {task.type_new === 'our' ? 'Our' : 'Partner'}</Typography>
                <Typography variant="body1">Completed: {task.completedCount || 0} times</Typography>
                <Typography variant="body1">Recurring: {task.isRecurring ? 'Yes' : 'No'}</Typography>
                {task.isRecurring && (
                  <Typography variant="body1">Repeat Interval: {task.repeatInterval}</Typography>
                )}
                <Typography variant="body1">Active: {task.active ? 'Yes' : 'No'}</Typography>
                <Typography variant="body1">Max Completions: {task.maxCompletions}</Typography>
                <Button variant="outlined" color="primary" sx={{ mr: 2 }} onClick={() => handleClickOpen(task)}>
                  Edit
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => handleDeleteDialogOpen(task)}>
                  Delete
                </Button>
              </Paper>
            ))}
          </Box>
        </Box>
      </Paper>

      {/* Add/Edit Task Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentTask ? 'Edit Task' : 'Add Task'}</DialogTitle>
        <DialogContent>
          {validationError && <Typography color="error">{validationError}</Typography>}
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={taskData.name}
            onChange={handleChange}
          />
          <TextField
            select
            margin="dense"
            name="type"
            label="Type"
            fullWidth
            variant="outlined"
            value={taskData.type}
            onChange={handleChange}
          >
            {Object.values(taskGroups).flat().map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            margin="dense"
            name="type_new"
            label="Category (Our/Partner)"
            fullWidth
            variant="outlined"
            value={taskData.type_new}
            onChange={handleChange}
          >
            {typeNewOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            name="priority"
            label="Priority"
            type="number"
            fullWidth
            variant="outlined"
            value={taskData.priority}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="details"
            label="Details (JSON)"
            type="text"
            fullWidth
            variant="outlined"
            value={taskData.details}
            onChange={handleChange}
            multiline
            rows={4}
          />
          <Button onClick={handleExampleDetails} color="primary">
            Load Example Details
          </Button>
          <TextField
            margin="dense"
            name="reward"
            label="Reward"
            type="number"
            fullWidth
            variant="outlined"
            value={taskData.reward}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="usdtReward"
            label="USDT Reward"
            type="number"
            fullWidth
            variant="outlined"
            value={taskData.usdtReward}
            onChange={handleChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={taskData.isRecurring}
                onChange={(e) => setTaskData({ ...taskData, isRecurring: e.target.checked })}
                name="isRecurring"
              />
            }
            label="Recurring"
          />
          {taskData.isRecurring && (
            <TextField
              margin="dense"
              name="repeatInterval"
              label="Repeat Interval (ms)"
              type="number"
              fullWidth
              variant="outlined"
              value={taskData.repeatInterval}
              onChange={handleChange}
            />
          )}
          <TextField
            margin="dense"
            name="maxCompletions"
            label="Max Completions"
            type="number"
            fullWidth
            variant="outlined"
            value={taskData.maxCompletions}
            onChange={handleChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={taskData.active}
                onChange={(e) => setTaskData({ ...taskData, active: e.target.checked })}
                name="active"
              />
            }
            label="Active"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Task Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>Delete Task</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this task?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Tasks;
